import * as React from 'react'
import { node, string, oneOfType, bool, object, any, oneOf } from 'prop-types'
import classNames from 'classnames'
import Text from '@/utils/Text'
import Center from '@/utils/Center'

const Button = React.forwardRef(
	(
		{
			children,
			as = 'button',
			theme = 'primary',
			disabled = false,
			className,
			large,
			href = false,
			target = false,
			...rest
		},
		ref
	) => {
		return (
			<Center
				as={as}
				ref={ref}
				inline
				disabled={disabled}
				className={classNames(
					className,
					'font-h-light rounded-30 px-lg h-40 md:h-48 hover:bg-none  whitespace-no-wrap',
					{
						'cursor-default opacity-50': disabled,
						'md:px-xl lg:px-2xl': large,
						'text-white  bg-gradient-b-primary hover:bg-primary':
							theme === 'primary',
						'text-primary border border-primary hover:bg-primary hover:text-white':
							theme === 'secondary'
					}
				)}
				{...rest}
			>
				{href ? (
					<Text as="a" className="leading-normal text-md" href={href} target={target}>
						{children}
					</Text>
				) : (
					<Text as="span" className="leading-normal text-md">
						{children}
					</Text>
				)}

			</Center>
		)
	}
)

Button.propTypes = {
	children: node.isRequired,
	className: string,
	disabled: bool,
	large: bool,
	as: oneOfType([string, object, any]),
	theme: oneOf(['primary', 'secondary'])
}

export default Button
