import * as React from 'react'
import classNames from 'classnames'
import { string, node, bool, element, object, oneOfType, any } from 'prop-types'
import Node from './Node'

const Center = React.forwardRef(
	({ as = 'div', children, className, inline = false, ...rest }, ref) => {
		return (
			<Node
				as={as}
				ref={ref}
				className={classNames(
					'items-center justify-center',
					{ flex: !inline, 'inline-flex': inline },
					className
				)}
				{...rest}
			>
				{children}
			</Node>
		)
	}
)

Center.propTypes = {
	children: node,
	className: string,
	as: oneOfType([string, object, node, element, any]), // added any to let the tests pass
	inline: bool
}

export default Center
