import * as React from 'react'
import classNames from 'classnames'
import { string, shape, number } from 'prop-types'

function Spinner({ className, style = { width: 40, height: 40 } }) {
	return (
		<div
			className={classNames('border-2 spinner-icon border-primary', className)}
			style={style}
			data-testid="spinner"
		/>
	)
}

Spinner.propTypes = {
	style: shape({
		width: number.isRequired,
		height: number.isRequired
	}),
	className: string
}

export default Spinner
