import * as React from 'react'
import { func, oneOfType, string, node, element } from 'prop-types'
import classNames from 'classnames'
import Node from '@/utils/Node'
import useConstant from '@/hooks/useConstant'
const toNum = num => parseFloat(num, 10)

function IconWrapper({
	icon,
	className,
	wrapperClassName,
	blockType = 'block',
	as = 'span',
	...props
}) {
	const svg = React.createElement(icon, {
		className: classNames(className, 'absolute inset-0 w-full h-full m-auto', {
			'fill-current': !className
		})
	})
	const { width, height, viewBox } = svg.props

	const ratio = useConstant(() => {
		const size = viewBox ? viewBox.split(' ').slice(2) : [width, height]
		return size
			.map(val => toNum(val))
			.reduceRight((y, x) => `${(y / x) * 100}%`)
	})

	return (
		<Node as={as} {...props} className={classNames(wrapperClassName, blockType)}>
			<span className="relative block w-full h-0" style={{ paddingTop: ratio }}>
				{svg}
			</span>
		</Node>
	)
}

IconWrapper.propTypes = {
	className: string,
	wrapperClassName: string,
	as: string,
	icon: oneOfType([func, node, element]).isRequired
}

export default React.memo(IconWrapper)
