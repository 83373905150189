import * as React from 'react'
import { string, node, bool } from 'prop-types'
import { Link as GatsbyLink } from 'gatsby'

const Link = React.forwardRef(
	({ children, to, activeClassName, partiallyActive, ...other }, ref) => {
		const internal = /^\/(?!\/)/.test(to)

		const file = /\.[0-9a-z]+$/i.test(to)

		// Use Gatsby Link for internal links, and <a> for others
		if (internal) {
			if (file) {
				return (
					<a href={to} download {...other} ref={ref}>
						{children}
					</a>
				)
			}
			return (
				<GatsbyLink
					to={to}
					activeClassName={activeClassName}
					partiallyActive={partiallyActive}
					{...other}
					innerRef={ref}
				>
					{children}
				</GatsbyLink>
			)
		}
		// External link
		return (
			<a href={to} {...other} ref={ref}>
				{children}
			</a>
		)
	}
)

Link.propTypes = {
	children: node.isRequired,
	to: string,
	activeClassName: string,
	partiallyActive: bool
}

export default Link
