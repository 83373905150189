import * as React from 'react'
import * as R from 'ramda'
import { string, node, bool, oneOfType, object, element, any } from 'prop-types'
import classNames from 'classnames'
import Node from './Node'

function addTypeSizeClass(className) {
	return (
		className && !className.includes('text-mob') && !className.includes('text-')
	)
}

function Text({ children, className, as = 'div', inline = false, ...rest }) {
	return (
		<Node
			as={as}
			className={classNames(
				'type-fix',
				{ 'md:inline-block': className && !className.includes('hidden') },
				className,
				{
					'text-mob-base text-base': addTypeSizeClass(className),
					'w-full': !inline,
					'hover:underline': as === 'a'
				}
			)}
			{...rest}
		>
			{children}
		</Node>
	)
}

Text.propTypes = {
	children: node,
	className: string,
	as: oneOfType([string, object, node, element, any]), //
	inline: bool
}

export default React.memo(Text, R.equals)
